module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TZSLTL3","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Milan Laser Hair Removal","short_name":"Milan Laser","description":"Laser Hair Removal | Milan Laser is one of the nation’s leading laser hair removal companies. We are committed to providing our clients with world-class customer service and permanent results. Our physicians and highly-trained medical staff utilize ongoing medical research to ensure that all treatments are performed safely and are tailored to give every client the best results possible. Our goal is to provide 100% customer satisfaction to every client and we will do everything we can to ensure our clients are happy with their experience as well as their results.","start_url":"/","background_color":"#F2FAFD","theme_color":"#F2FAFD","display":"standalone","icon":"/opt/build/repo/theme-2023/src/images/favicon.webp","icons":[{"src":"/opt/build/repo/theme-2023/src/images/android-chrome-192x192.webp","sizes":"192x192","type":"image/png"},{"src":"/opt/build/repo/theme-2023/src/images/android-chrome-256x256.webp","sizes":"256x256","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d86e0a17ee6e0d2e70fbdb8db6f8086c"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../theme-2023/gatsby-browser.js'),
      options: {"plugins":[],"siteData":{"grandOpening":false,"stores":[{"salesforceValue":"Palm Coast","rating":5,"phone":"386-387-2249","clinicName":"","addressCity":"","store_email":"palmcoast@milanlaser.com","description":{"line_one":"on the east side of I-95 off Palm Coast Highway","line_two":" in the Island Walk Shopping Center between the UPS store and Asurion Tech repair near Publix and Petco"},"marketingCity":"Palm Coast","open":true,"address":"250 Palm Coast Parkway NE  Suite 701","timezone":"Eastern","pathname":"palmcoast","state":"Florida","platforms":{"reviews":{"slide_token":"bZpeaNx21tcJ5Ub5tUk8f6t5boBuOkoYlTfuVOGW0tcbz3DuYr","list_token":"rQKdtKJLGgo2yod1TWsF07tAtvRvKLmG0vRJDYhY83EkocGurp"},"google":"https://www.google.com/search?client=safari&rls=en&q=milan+laser+hair+removal+palm+coast+fl&ie=UTF-8&oe=UTF-8#lrd=0x88e69536b77b4fdb:0xfb41877b3ac911a1,3,,,,","yelp":"palm-coast","facebook":"palmcoast"},"openDate":{"seconds":1687150800,"nanoseconds":0},"zipCode":"32137","geo":{"long":"-81.210976","lat":"29.557841"},"cm_pardot_file":"301 - 325","with_pro_images":true,"place_id":"ChIJ2097tzaV5ogRoRHJOnuHQfs","reviewCount":45,"stateAbbrev":"FL","maps":{"google":"https://goo.gl/maps/BFFVWgn5kdphtu8d6","apple":"https://maps.apple.com/?address=250%20Palm%20Coast%20Pkwy%20NE,%20Unit%20701,%20Palm%20Coast,%20FL%20%2032137,%20United%20States&auid=6571742083021779939&ll=29.557841,-81.210976&lsp=9902&q=Milan%20Laser%20Hair%20Removal"},"hero":{"reversed":true,"position":"center"}}],"openStores":1,"metroPath":"palmcoast","name":"Palm Coast","google_analytics_id":"","state":"Florida","stateAbbrev":"FL","gtag_id":"GTM-TZSLTL3","origin":"https://milanlaserpalmcoast.com","bing_id":"","google_verification":"","pathname":"palmcoast"}},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
